.a-filter-chips {
  display: flex;
  align-items: center;
  background: $grey-200;
  border-radius: 12px;
  padding: 0 1rem;
  height: 3rem;

  &__label {
    font-size: 12px;
    line-height: 18px;
    color: $grey-400;
    white-space: nowrap;
  }

  &__button {
    position: relative;
    width: 2rem;
    height: 2rem;
    padding: .5rem;
    border: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 11px;
      height: 1px;
      background-color: $grey-400;
      border-radius: 2px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}