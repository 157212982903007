.l-stock {
  &__intersection-observer {
    height: 1px;
    position: relative;
    top: -1px;
  }
  &__form {
  }

  &__filters {
  }

  .filters {
    &__top {
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2rem;
        border-bottom: 1px solid $grey-300;
      }
    }

    .top {
      &__heading {
        margin-bottom: 1.25rem;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }
    }

    &__content {
      margin-bottom: 2rem;

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1.5rem;
        padding: 2rem 0;
      }
    }

    .content {
      &__item {
        margin-bottom: 1rem;

        @include media-breakpoint-up(lg) {
          width: calc(25% - 3rem);
          margin: 0 1.5rem 1rem;
        }

        &--additional {
          display: none;

          @include media-breakpoint-up(lg) {
            display: block;
          }
        }

        &--range {
          margin-top: 2rem;

          @include media-breakpoint-up(lg) {
            width: calc(50% - 3rem);
            margin-top: 0;
          }
        }
      }

      &__row {
        display: flex;
        flex-direction: column-reverse;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          width: 100%;
          flex-wrap: wrap;
        }
      }

      &__input-range {
        @include media-breakpoint-up(lg) {
          margin-top: 0;
          display: flex;
          align-items: center;

          .a-input-range {
            &__top {
              margin-bottom: 0;
              margin-right: 1.5rem;
              width: calc(50% - 1.5rem);
            }

            &__inputs {
              margin-left: 1.5rem;
              margin-top: 0;
              width: calc(50% - 1.5rem);
            }
          }

        }
      }

      &__bottom {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 2rem 0;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      &__additional-filters {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height $transition-base;

        @include media-breakpoint-up(lg) {
          max-height: unset;
          overflow: unset;
        }
      }

      .additional-filters {
        &__inner {
          @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
          }
        }
      }

      &__additional-filters-button {
        font-size: 14px;
        color: $secondary;
        border: none;
        padding: 0;
        background-color: transparent;
      }

      &__selected-filters {
        position: relative;
        display: flex;
        width: 100%;
        min-height: 32px;
        margin-top: 1rem;

        @include media-breakpoint-up(lg) {
          padding: 0 1.5rem;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          width: 20px;
          height: 100%;
          pointer-events: none;

          @include media-breakpoint-up(lg) {
            display: none;
          }
        }

        &::before {
          left: 0;
          background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        }

        &::after {
          right: 0;
          background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        }
      }

      .selected-filters {
        &__inner {
          display: flex;
          width: 100%;
          overflow-x: auto;

          @include media-breakpoint-up(lg) {
            overflow-x: unset;
            flex-wrap: wrap;
          }
        }

        &__label {
          font-size: 14px;
          line-height: 20px;
          margin-right: 1rem;
          white-space: nowrap;
          display: none;

          &.is-active {
            @include media-breakpoint-up(lg) {
              display: block;
            }
          }
        }

        &__filter-chips {
          margin: 0 .5rem 1rem;

          @include media-breakpoint-up(lg) {
            margin: 0 1rem 1rem 0;
          }

          &:first-child {
            margin-left: 1rem;

            @include media-breakpoint-up(lg) {
              margin-left: 0;
            }
          }
        }
      }

      &__submit {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 2rem;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding: 0 1.5rem;
        }

        &-clear-filters-button {
          font-size: 14px;
          line-height: 20px;
          color: $secondary;
          border: none;
          padding: 0;
          background-color: transparent;
          margin-bottom: 2rem;
          visibility: hidden;
          pointer-events: none;

          &.is-active {
            visibility: visible;
            pointer-events: all;
          }

          @include media-breakpoint-up(lg) {
            margin-right: 3rem;
            margin-bottom: 0;
          }
        }

        &-button {
          width: 100%;
          display: block;

          @include media-breakpoint-up(lg) {
            width: auto;
            margin-top: 0;
          }
        }
      }
    }
  }

  &__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba($white, 0.5);
    opacity: 0;
    pointer-events: none;

    .a-spinner {
      position: absolute;
      left: 50%;
      top: 10%;
      transform: translateX(-50%);
    }
  }

  &__cars-wrapper {
    position: relative;

    &.is-loading {
      .l-stock__loading {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__cars {

  }

  .cars {
    &__heading {
      text-align: center;
      color: $grey-400;

      &-count {
        color: $black;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 4rem;
      }
    }

    &__list {
      list-style-type: none;
      padding: 0;

      &-item {
        &:nth-child(even) {
          .o-stock-car {
            background-color: $white;
          }
        }
      }
    }
  }
}