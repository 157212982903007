.a-arrow {
  display: block;
  width: 12px;
  height: 12px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
    border-top-color: $black;
    border-right-color: $black;
    transform: translate(-75%, -50%) rotate(45deg);
  }

  &--small {
    width: 8px;
    height: 8px;
  }

  &--white {
    &::before {
      border-top-color: $white;
      border-right-color: $white;
    }
  }

  &--secondary {
    &::before {
      border-top-color: $secondary;
      border-right-color: $secondary;
    }
  }

  &--bottom {
    &:before {
      transform: translate(-50%, -75%) rotate(135deg);
    }
  }

  &--left {
    &:before {
      transform: translate(-25%, -50%) rotate(228deg);
    }
  }
}