.a-button {
  display: inline-block;
  padding: 1.125rem 2rem;
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: $primary;
  font-weight: $font-weight-medium;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  color: $white;
  transition: transform 0.1s ease-out, background 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;

  &:hover {
    color: $white;
    background-color: rgba($blue,.8);
    border-color: rgba($blue,.8);
  }

  @include media-breakpoint-up(lg) {
    min-width: 246px;
  }

  &--secondary {
    background-color: transparent;
    color: $primary;

    &:hover {
      background-color: transparent;
      color: $primary;
      border-color: rgba($primary,.1);
    }
  }

  &--disabled{
    opacity: 50%;
    pointer-events: none;
  }
}