.o-form {

  &__loading {
    z-index: 10;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: white;
    transition: opacity $transition-base;

    .a-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .form {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0 0 auto;
      padding: 2rem 3rem;
      border-bottom: solid 1px $grey-300;

      &-text {
        font-size: 16px;
        line-height: 1.5;
        margin: 0;
      }

      &-close {
        padding: 2rem;
        margin: -2rem;
      }
    }

    &__content {
      overflow-x: hidden;
      overflow-y: auto;
      flex: 10 1 auto;
      padding: 1rem 2rem;

      @include media-breakpoint-up(xs) {
        padding: 1rem 3rem;
      }

      &-section {
        margin-top: 1.5rem;
      }

      &-subtitle {
        display: block;
        margin-bottom: .5rem;
        font-size: 14px;
        font-weight: $font-weight-medium;
        line-height: 24px;
      }

      &-field {
        margin-top: 1rem;

        &:first-child {
          margin-top: 0;
        }
      }

      &-consents {
        margin-top: 2rem;
      }

      &-annotation {
        padding-top: 2rem;
        border-top: solid 1px $grey-300;
        margin-top: 2rem;
        font-size: 12px;
        line-height: 1.5;
        color: $grey-400;

        p:last-child {
          margin-bottom: 0;
        }
      }

      &-extra-fields{
        font-size: 14px;
      }
    }

    &__datetime {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      line-height: 24px;

      @include media-breakpoint-up(sm) {
        justify-content: space-between;
      }
    }

    .datetime {
      &__date {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 1.25rem;

        @include media-breakpoint-up(sm) {
          flex: 1 1 auto;
          width: auto;
          margin: 0;
        }
      }

      &__time {
        flex: 1 1 auto;
        width: 50%;

        @include media-breakpoint-up(sm) {
          flex: 0 0 auto;
          width: auto;
          margin-left: 1rem;
        }
      }

      &__text {
        flex: 0 0 auto;
        width: 100%;
        margin-top: 1rem;
        font-size: 12px;
        line-height: 18px;
        color: $grey-400;

        @include media-breakpoint-up(sm) {
          margin-top: 0;
        }
      }
    }

    &__footer {
      flex: 0 0 auto;
      padding: 3rem;
      box-shadow: 0px -4px 4px rgba(213, 213, 213, 0.5);

      &-button {
        max-width: unset;
        width: 100%;
      }
    }
  }

  &__thanks {
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 3rem;
  }

  .thanks {
    &__close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 2rem;
    }

    &__inner {

    }

    &__image {
      width: 100%;
    }

    &__title {
      margin-bottom: 2rem;
      text-align: center;
      color: $grey-400;
    }
  }

  &.is-loading {
    .o-form {
      &__loading {
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  &.is-completed {
    .o-form {
      &__form {
        display: none;
      }

      &__thanks {
        display: flex;
      }
    }
  }
}