$white: #fff;
$black: #141414;

$blue: #284E80;
$blue-light: #1C6BBA;

$primary: $blue;
$secondary: $blue-light;

$red-error: #BF2012;
$orange: #BE834A;
$green: #69875C;


$grey-100: #FAFAFA;
$grey-200: #EBEBEB;
$grey-300: #D5D5D5;
$grey-400: #707070;

$body-color: $black;
$body-bg: $white;
